import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
import * as sbc from '@tvlgiao/bc-supermarket-builder-components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "product-page"
    }}>{`Product Page`}</h1>
    <h2 {...{
      "id": "responsive-main-product-images"
    }}>{`Responsive main product images`}</h2>
    <p>{`To display the main product images automatically changing quality according to screen size, and especially displaying high quality images for retina displays, you can enable this feature in the `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Products`}</strong>{` > `}<strong parentName="p">{`Product page`}</strong>{` > tick on `}<strong parentName="p">{`Responsive main product images`}</strong>{`.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` If your original image is larger than 10Mb or it's PNG format, you should NOT enable this feature because it will take more time to generate many different size images, which affect the speed of image display.`}</p>
    <h2 {...{
      "id": "display-sale-badget--custom-badges"
    }}>{`Display Sale badget & custom badges`}</h2>
    <p><img alt="product badges" src={require("../img/product-card-badges.png")} /></p>
    <h3 {...{
      "id": "sale-badges"
    }}>{`Sale badges`}</h3>
    <p>{`You need to input both `}<strong parentName="p">{`Sale Price`}</strong>{` and `}<strong parentName="p">{`Retail Price`}</strong>{` of product:`}</p>
    <p><img alt="edit product sale prices" src={require("../img/edit-product-sale-price.png")} /></p>
    <p>{`In `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Products`}</strong>{` > `}<strong parentName="p">{`Product Sale Badges`}</strong>{`, choose to display product Sale badge:`}</p>
    <p><img alt="enable badges on theme editor" src={require("../img/theme-editor-badges.png")} /></p>
    <h3 {...{
      "id": "custom-badges"
    }}>{`Custom Badges`}</h3>
    <p>{`To display the custom badges, enable `}<strong parentName="p">{`Custom Badges`}</strong>{` option in `}<strong parentName="p">{`Theme Editor`}</strong>{`. Edit your product, add a custom field with name = `}<inlineCode parentName="p">{`__badge`}</inlineCode>{` and value is the custom badget content.`}</p>
    <p><img alt="edit product badges in custom fields" src={require("../img/edit-product-custom-badges.png")} /></p>
    <h3 {...{
      "id": "custom-image-badge"
    }}>{`Custom image badge`}</h3>
    <p><img alt="custom-image-badge" src={require("../img/custom-image-badge.png")} /></p>
    <p>{`Enable `}<strong parentName="p">{`Custom Badges`}</strong>{` option in `}<strong parentName="p">{`Theme Editor`}</strong>{`.`}</p>
    <p>{`Upload a badge image in `}<strong parentName="p">{`Storefront`}</strong>{` > `}<strong parentName="p">{`Image Manager`}</strong>{`, for example: `}</p>
    <p><img alt="upload-badge-image-in-image-manager" src={require("../img/upload-badge-image-in-image-manager.jpg")} /></p>
    <p>{`Then click on the image to open the image on a new browser tab. Copy the image file name from your browser address bar:`}</p>
    <p><img alt="copy-badge-image-file-name" src={require("../img/copy-badge-image-file-name.png")} /></p>
    <p>{`In this example, the image file name is `}<inlineCode parentName="p">{`low-everyday-price.png`}</inlineCode>{`.`}</p>
    <p>{`Edit your product and create a custom field with name = `}<inlineCode parentName="p">{`__badge`}</inlineCode>{` and value = `}<inlineCode parentName="p">{`image:low-everyday-price.png`}</inlineCode>{` where `}<inlineCode parentName="p">{`low-everyday-price.png`}</inlineCode>{` is the example image file name uploaded above.`}</p>
    <p><img alt="edit-product-custom-image-badge" src={require("../img/edit-product-custom-image-badge.png")} /></p>
    <p>{`Go to `}<strong parentName="p">{`Storefront`}</strong>{` > `}<strong parentName="p">{`Script Manager`}</strong>{`, click `}<strong parentName="p">{`Create a Script`}</strong>{` button to insert the custom script.`}</p>
    <p>{`Enter:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Name of script`}</strong>{` = `}<inlineCode parentName="li">{`Custom image product badge`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Location on page`}</strong>{` = `}<inlineCode parentName="li">{`Footer`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Select pages where script will be added`}</strong>{` = `}<inlineCode parentName="li">{`Store pages`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Script category`}</strong>{` = `}<inlineCode parentName="li">{`Essential`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Script type`}</strong>{` = `}<inlineCode parentName="li">{`Script`}</inlineCode></li>
    </ul>
    <p>{`Enter `}<strong parentName="p">{`Script contents`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script>
(function($) {
    function debounce(n,t,u){var e;return function(){var i=this,o=arguments,a=u&&!e;clearTimeout(e),e=setTimeout(function(){e=null,u||n.apply(i,o)},t),a&&n.apply(i,o)}}

    function main() {
        $('.sale-flag-side--custom:not(.imageBadgeLoaded)').each(function(i, el) {
            var $el = $(el);
            $el.addClass('imageBadgeLoaded');
            var s = $el.text().trim();
            if (s.indexOf('img:') === 0) {
                var $img = $('<img/>')
                    .attr('alt', s)
                    .attr('src', s.replace('img:', '/product_images/uploaded_images/'));
                $el.html('').append($img).css({ 'background-color': 'transparent', 'padding': '0' });
            }
        });
    }

    main();
    (new MutationObserver(debounce(main, 300))).observe(document.body, { childList: true, subtree: true });
})(window.jQuerySupermarket || window.chiarajQuery || window.jQueryTheme || window.jQuery);
</script>
`}</code></pre>
    <h2 {...{
      "id": "banners"
    }}>{`Banners`}</h2>
    <p><img alt="banners on product page" src={require("../img/home1-product-page-banners.png")} /></p>
    <p>{`Theme supports various banner positions on the product pages. You can enable it in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Banner below ratings on product page`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Banner below Add To Cart button on product page`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Banner above description tabs on product page`}</strong></li>
    </ul>
    <p>{`After enabled, you can edit the banner content by go to `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{`, click button `}<strong parentName="p">{`Create a Banner`}</strong>{`. In `}<strong parentName="p">{`Banner Content`}</strong>{`, click the square `}<inlineCode parentName="p">{`HTML`}</inlineCode>{` button to open `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <p>{`Use the editor below to generate HTML. Click `}<strong parentName="p">{`Export HTML`}</strong>{` button, copy & paste the generated HTML to `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <sbc.ProductBannerBuilderComponent />
    <p>{`Choose other options:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Show on Page`}</strong>{`: `}<inlineCode parentName="li">{`Search Results Page`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Visible`}</strong>{`: Yes`}</li>
      <li parentName="ul"><strong parentName="li">{`Location`}</strong>{`: `}<inlineCode parentName="li">{`Top of Page`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "custom-tabs"
    }}>{`Custom Tabs`}</h2>
    <p>{`To add the custom tabs and product pages, edit your product description, click on `}<strong parentName="p">{`HTML`}</strong>{` button to open `}<strong parentName="p">{`HTML Source Editor`}</strong>{`:`}</p>
    <p><img alt="edit-product-custom-tabs" src={require("../img/edit-product-custom-tabs.png")} /></p>
    <p>{`Use the editor below to generate HTML for your custom tabs. Click `}<strong parentName="p">{`Export HTML`}</strong>{` button, copy & paste the generated HTML at the end of `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <sbc.ProductCustomTabsBuilderComponent />
    <h2 {...{
      "id": "frequently-bought-together"
    }}>{`Frequently Bought Together`}</h2>
    <p><img alt="Frequently bought together feature" src={require("../img/bought-together.jpg")} /></p>
    <p>{`To display `}<strong parentName="p">{`Frequently Bought Together`}</strong>{` feature, go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Products`}</strong>{` > `}<strong parentName="p">{`Product Page`}</strong>{` > `}<strong parentName="p">{`Show buy together`}</strong>{`, choose position to display:`}</p>
    <p><img alt="theme editor bought together" src={require("../img/theme-editor-bought-together.png")} /></p>
    <h3 {...{
      "id": "associate-bought-together-products-to-a-specific-product"
    }}>{`Associate "bought together" products to a specific product`}</h3>
    <p>{`Then, edit a product, in `}<strong parentName="p">{`Custom Fields`}</strong>{` tab, add some custom fields with name `}<inlineCode parentName="p">{`__alsobought`}</inlineCode>{` and value is the "bought together" `}<strong parentName="p">{`product ID`}</strong>{` you want to associate:`}</p>
    <p><img alt="Edit product bought together" src={require("../img/edit-product-bought-together.png")} /></p>
    <p>{`To find the `}<strong parentName="p">{`product ID`}</strong>{`, edit the product, then look at the URL address box on your web browser, the number between `}<inlineCode parentName="p">{`products/`}</inlineCode>{` and `}<inlineCode parentName="p">{`/edit`}</inlineCode>{` is the product ID.`}</p>
    <p><img alt="Find product ID" src={require("../img/find-product-id.png")} /></p>
    <h2 {...{
      "id": "shipping-date-notification-beta"
    }}>{`Shipping Date Notification (BETA)`}</h2>
    <p><img alt="shipping-date-notification" src={require("../img/shipping-date-notification.png")} /></p>
    <p><em parentName="p"><strong parentName="em">{`Warning: This feature is Beta testing. We do not guarantee with work as your expectation.`}</strong></em></p>
    <p>{`This notification displays estimated shipping automatically.`}</p>
    <p>{`Enable `}<strong parentName="p">{`Banner below Add To Cart button on product page`}</strong>{` in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{`.`}</p>
    <p>{`Next, create a banner in `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{`:`}</p>
    <p><img alt="edit-banner-for-shipping-date-notification" src={require("../img/edit-banner-for-shipping-date-notification.png")} /></p>
    <p><strong parentName="p">{`Content:`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div id="emthemesModezBannersBlockAfterAddToCartButton" class="emthemesModezBannersBlockAfterAddToCartButton">
<div class="emthemesModez-section emthemesModez-section--banners-one">
<div class="emthemesModez-section-inner">
<div class="emthemesModez-bannersContainer emthemesModez-bannersContainer--one">
<div class="emthemesModez-banner">
<div class="emthemesModez-banner-container">
<p data-blackout-dates="02-05,03-01,05-02" data-deadline-time="13" data-shipping-day="2" data-time-offset="-5"><strong>Want it on <span data-shipping-date="dddd, MMM. d">***</span>?<br /></strong> Choose <strong>Priority Mail</strong> at checkout.</p>
</div>
</div>
</div>
</div>
</div>
</div>
`}</code></pre>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`data-blackout-dates="02-05,03-01,05-02"`}</inlineCode>{`: Configure backout dates of year`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`data-deadline-time="13"`}</inlineCode>{`: Configure deadline time when postal service is closed.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`data-shipping-day="2"`}</inlineCode>{`: Configure shipping days.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`data-time-offset="-5"`}</inlineCode>{`: Configure your time offset.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`data-shipping-date="dddd, MMM. d"`}</inlineCode>{`: Configure print out date format.`}</li>
    </ul>
    <p><em parentName="p"><strong parentName="em">{`Note:`}</strong>{` Don't remove `}<inlineCode parentName="em">{`emthemesModezBannersBlockAfterAddToCartButton`}</inlineCode>{`.`}</em></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Show on Page`}</strong>{`: `}<inlineCode parentName="li">{`Search Results Page`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Location`}</strong>{`: `}<inlineCode parentName="li">{`Top of Page`}</inlineCode></li>
    </ul>
    <p>{`Next, insert the custom javascript below to `}<strong parentName="p">{`Storefront`}</strong>{` > `}<strong parentName="p">{`Footer Scripts`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script src="https://cdnjs.cloudflare.com/ajax/libs/datejs/1.0/date.min.js"></script>
<script>
// <!--
window.jQuerySupermarket(function(e){function t(){if(e("body").hasClass("papaSupermarket-page--pages-product"))var t=setInterval(function(){var a=e("#emthemesModezBannersBlockAfterAddToCartButton");if(a.length>0){clearInterval(t);var i=a.find("[data-deadline-time]");if(i.length>0){var n=parseInt(i.data("deadline-time"),10),r=parseInt(i.data("shipping-day"),10),d=[];i.data("blackout-dates").split(",").forEach(function(e){var t=e.split("-");d.push(t)});var s=parseInt(i.data("time-offset"),10),o=new Date;o.setTime(o.getTime()+60*s*60*1e3),o.getUTCHours()>=n&&o.setTime(o.getTime()+864e5);var p,g=!1;do{if((p=!1)||0!=o.getUTCDay()||(p=!0),p||6!=o.getUTCDay()||g||(p=!0),!p)for(var l in d)if(d[l][0]==o.getUTCMonth()+1&&d[l][1]==o.getUTCDate()){p=!0;break}(r>0||p)&&o.setTime(o.getTime()+864e5),p||(r--,g=!0)}while(r>=0||p);o.setTime(o.getTime()-60*s*60*1e3);var m=e("[data-shipping-date]");m.length>0&&m.html(o.toString(m.data("shipping-date")))}}},1e3)}t(),e("body").on("loaded.instantload",t)});
// -->
</script>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      